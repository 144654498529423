import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements'
import EditFormControl from './EditFormControl';


const BaseInput = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle='Membres' parent='Modifier un membre' title='Modifier un membre' subParent='Membres' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <EditFormControl/>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BaseInput;
