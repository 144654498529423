import React, { Fragment, useState } from 'react';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P, Image } from '../AbstractElements';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { BiSolidShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { toast } from 'react-toastify';
import { POST, UPDATE } from '../api/Axios';

const UnlockUser = ({ logoClassMain }) => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [changePassword, setChangePassword] = useState({
        password: '',
        confirm_password: ''
    });
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleChangePassword, setToggleChangePassword] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);





    const handleSend = async (e) => {
        e.preventDefault();

        if (changePassword.password !== changePassword.confirm_password) {
            setError("Confirmer que le mot de passe ne correspond pas au mot de passe")
        }
        if (changePassword.password.length < 8) {
            setError("La longueur du nouveau mot de passe doit être supérieure à 7 caractères");
            return;
          }
        else {
            setError(null);
            setLoading(true);
            const response = await UPDATE('/auth/reset_password/' + token, changePassword, setLoading);
            if (response) {
                setSuccess(response.data.message);
                setLoading(false);
                setDisableBtn(true)
                setChangePassword({
                    password: '',
                    confirm_password: ''
                })
            }
        }

    };


    return (
        <Fragment>
            <section>
                <Container fluid={true} className='p-0 login-page'>
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <div className='login-card'>
                                <div>
                                    <div>
                                        {/* Logo */}
                                    </div>
                                    <div className='login-main unlock-user'>
                                        <Form className='theme-form login-form' onSubmit={handleSend}>
                                            <H4>Réinitialisez votre mot de passe</H4>
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label'>nouveau mot de passe</Label>
                                                <div className='position-relative'>
                                                    <Input
                                                        className='form-control'
                                                        required
                                                        type={togglePassword ? 'text' : 'password'}
                                                        placeholder='*************'
                                                        value={changePassword.password}
                                                        onChange={(e) => setChangePassword({ ...changePassword, password: e.target.value })}
                                                    />
                                                    <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}  style={{fontSize:'20px',cursor:'pointer'}}>
                                                        {togglePassword ? <BiHide/> : <BiSolidShow/>}
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label'>Confirmer le nouveau mot de passe</Label>
                                                <div className='position-relative'>
                                                    <Input
                                                        className='form-control'
                                                        required
                                                        placeholder='*************'
                                                        type={toggleChangePassword ? 'text' : 'password'}
                                                        value={changePassword.confirm_password}
                                                        onChange={(e) => setChangePassword({ ...changePassword, confirm_password: e.target.value })}
                                                    />
                                                    <div className='show-hide' onClick={() => setToggleChangePassword(!toggleChangePassword)}  style={{fontSize:'20px',cursor:'pointer'}}>
                                                        {toggleChangePassword ? <BiHide/> : <BiSolidShow/>}
                                                    </div>
                                                      
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <p style={{ color: 'red', fontSize: '13px' }}>{error != null ? error : ''}</p>
                                                <p style={{ color: 'green', fontSize: '13px' }}>{success != null ? success : ''}</p>
                                            </FormGroup>
                                            <FormGroup>
                                                <button className='btn w-100' disabled={loading || disableBtn}>{loading ? 'Mise à jour...' : 'Mettre à jour le mot de passe'}</button>
                                            </FormGroup>
                                            <P attrPara={{ className: 'mb-0' }}>
                                                Vous avez déjà un compte?
                                                <Link className='ms-2' to='/' style={{ color: '#6b2a7d' }}>
                                                    Se connecter
                                                </Link>
                                            </P>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default UnlockUser;
