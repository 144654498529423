import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  LogIn,Settings, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, LogOut, Member, Password } from "../../../Constant";
import { useUser } from "../../../Auth/UserContext"; // Import the useUser hook
import { GET } from '../../../api/Axios'
import { Link } from "react-router-dom";
import SampleImage from '../../../assets/images/user/sample.png'

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [user, setUser]=useState([])
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(null);
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const userRole=localStorage.getItem('role');


  const fetchData=async()=>{
    const response= await GET('user/profile', setLoading);
    
    if(response){
    setUser(response.data)
    }
    
  }
  // Fetch user data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const Logout = () => {
    console.log('Logging out - removing token and user...');
    history(`/`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
      <Link to="/profil">
        <Image
          attrImage={{
            className: "b-r-10 m-0 image-fluid",
            src: user?.profile_image ? user?.profile_image?.url:SampleImage,
            alt: "",
            style:{height:"40px",width:"40px"}
          }}
        
        />
        </Link>
        <div className="media-body">
        {/* Bienvenue */}
          <span>{user?.name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {user?.job_designation}
            {/* {userRole ==="admin" ? (
              <>
                {'Administrateur'}
              </>
            ) : (
              <>
                {'Membre'}
              </>
            )} */}
          </P>
        </div>
      </div>
      {/* <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => {
              history('/password');
            },
          }}>
          <Settings />
          <span>{Password}</span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL> */}
    </li>
  );
};

export default UserHeader;
