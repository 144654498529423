import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { H5, Btn } from '../../../AbstractElements'
import { GET } from '../../../api/Axios';
import { CardHeader, CardFooter } from 'reactstrap';
// import FooterCard from '../Common/FooterCard';
import { Data } from './Data';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { POSTFILE, UPDATE } from '../../../api/Axios';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const EditFormControl = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const validationSchema = yup.object({
        name: yup.string('Only Alphabets are allowed').required('Veuillez entrer un nom').min(2, 'Le nom doit comporter au moins 2 caractères').typeError('Seuls les alphabets sont autorisés'),
        email: yup.string().required('Veuillez entrer votre email').email().typeError('Seuls les alphabets sont autorisés'),
        // password: yup.string().required('Veuillez entrer le mot de passe').typeError('Seuls les alphabets sont autorisés').min(8, "La longueur du mot de passe doit être supérieure à 7"),
        phone: yup.string().required('Veuillez entrer le téléphone').typeError('Seuls les alphabets sont autorisés'),
        street: yup.string().typeError('Seuls les alphabets sont autorisés'),
        city: yup.string().typeError('Seuls les alphabets sont autorisés'),
        zip: yup.string().typeError('Seuls les alphabets sont autorisés'),
    });



    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            // password: '',
            job_designation:'',
            phone: '',
            street: '',
            city: '',
            zip: '',
            profile_image: null,
            role: ''
        },
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            setLoading(true)
            const token = localStorage.getItem('token');
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/user/member/${params?.id}`, values,

                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'authorization':`${token}`,
                    }
                });
            if (response) {
                setLoading(false)
                toast.success(response.data.message)
                formik.resetForm();
                navigate('/membres');
            }
            
        },
    });
    const fetchData = async () => {
        setLoading(true)
        const response = await GET(`/user/${params?.id}`, setLoading);
        if (response) {
            // setData(response.data)
         
            if (response.data) {
                formik.setValues({
                    name: response.data.name ?? '',
                    email: response.data.email ?? '',
                    // password: response.data.password ?? '',
                    job_designation: response.data.job_designation ?? '',
                    phone: response.data.phone ?? '',
                    street: response.data.street ?? '',
                    city: response.data.city ?? '',
                    zip: response.data.zip ?? '',
                    profile_image: response.data?.profile_image ?? '',
                    role: response.data.role ?? '',
                });
            }
            setLoading(false)
        }

    };
    useEffect(() => {
        fetchData();

    }, []);

    return (
        <Fragment>
            <Card>
                <CardHeader > <H5> Modifier un membre</H5>   </CardHeader>
                <Form className="form theme-form" onSubmit={formik.handleSubmit} method='post'>
                    <CardBody>
                        <Row className='mb-3'>

                            {Data.map((item, index) => (
                                <Col md="4" key={index}>
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlInput1">{item.title}</Label>
                                        {item.type == 'file' ?
                                            <>
                                                <label htmlFor="fileInput" style={{ cursor: 'pointer', border: '1px solid #dee2e6', width: '100%', height: '40px', borderRadius: '5px', padding: '7px' }}>
                                                    Choisir le fichier: {formik.values[item.name] != null ? formik.values[item.name].name : 'Aucun fichier...'}
                                                </label>
                                                <Input className="form-control" id="fileInput" style={{ display: 'none' }} name={item.name} type={item.type} onChange={(e) => formik.setFieldValue(item.name, e.currentTarget.files[0])} />
                                            </>
                                            :
                                            <Input className="form-control" name={item.name} type={item.type} placeholder={item.placeholder} value={formik.values[item.name]} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                        }
                                        <small style={{ color: "red" }}>  {formik.touched[item.name] && formik.errors[item.name]}</small>
                                    </FormGroup>
                                </Col>

                            ))}

                        </Row>

                    </CardBody>
                    <CardFooter className="text-end">
                        <button className='btn' disabled={loading} type='submit'>{loading ? 'Sauvegarder...' : 'Sauvegarder'}</button>
                        <button className='btn' type='button' disabled={loading} onClick={() => navigate('/membres')}>Annuler</button>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default EditFormControl;