
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import 'react-tooltip/dist/react-tooltip.css'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { MdClose, MdMoreVert, MdDelete, MdCreateNewFolder, MdDownload } from 'react-icons/md';



const handleDownloadFile = async (file) => {
  const { name, path } = file;
  const fileURL = path.url;
  let fileName = name;

  try {
    const response = await fetch(fileURL);
    const blob = await response.blob();
     
    if (fileName.startsWith('.')) {
      fileName = ` ${fileName}`;
    }
    const finalFileName = fileName;

    // Create a blob URL and trigger a download
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = blobURL;
    link.download = finalFileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the blob URL to free up resources
    window.URL.revokeObjectURL(blobURL);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

const getFileExtensionFromURL = (url) => {
  const pathParts = url.split('/');
  const fileNameWithExtension = pathParts[pathParts.length - 1];
  const dotIndex = fileNameWithExtension.lastIndexOf('.');
  return dotIndex !== -1 ? fileNameWithExtension.slice(dotIndex + 1) : '';
};


const MyNoRenderer = ({ document, fileName }) => {
  const fileText = fileName || document?.fileType || "";
  if (fileText) {
    return <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
      <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
        <div>Ce fichier ne peut pas être consulté. Veuillez réessayer ou télécharger.</div>
      </div>
    </div>;
  }

  return <div>Aucune erreur de rendu!</div>;
};
const FileViewerModal = ({ file, showModal, handleCloseModal }) => {
  const [error, setError] = useState(null);
  const docs = [{ uri: file?.path?.url }];
  const [useCustomZoom, setUseCustomZoom] = useState(false);


  const supportedFormats = [
    'bmp', 'csv', 'odt', 'doc', 'docx', 'gif', 'htm', 'html', 'jpg', 'jpeg',
    'pdf', 'png', 'ppt', 'pptx', 'tiff', 'txt', 'xls', 'xlsx', 'mov','mp4'
  ];

  const isSupportedFormat = supportedFormats.some(format => file.path.url.endsWith(`.${format}`));

  useEffect(() => {
    const handleResize = () => {
      setUseCustomZoom(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const docViewerConfig = {
    header: {
      disableHeader: true,
      disableFileName: true,
      retainURLParams: true,
    },
    csvDelimiter: ",", 
    pdfZoom: {
      defaultZoom: useCustomZoom ? 1.2 : 0.65, 
      zoomJump:  useCustomZoom ? 0.35 : 0.2, 
   
    },
    pdfVerticalScrollByDefault: true,
    noRenderer: {
      overrideComponent: MyNoRenderer,
    },
  };

  try {
    if (!file.path.url) {
      throw new Error('Invalid file URL.');
    }
    
  if (!isSupportedFormat) {
    return (
      <Modal show={showModal} onHide={handleCloseModal} size="fullscreen" centered>
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <div className="icon-wrapper">
            <MdDownload style={{ marginRight: '10px' }} onClick={() => handleDownloadFile(file)} id='downloadBtn' />
            <MdClose style={{ marginRight: '5px' }} onClick={handleCloseModal} id='closeBtn' />
          </div>
        </Modal.Header>

        <Modal.Body style={{ display: 'grid', placeItems: 'center', width: '100%' }} >
          <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
        <div>Ce fichier ne peut pas être consulté. Veuillez réessayer ou télécharger.</div>
        </div>
        </Modal.Body>
      </Modal>
    );
  }
    return (
      <Modal show={showModal} onHide={handleCloseModal} size="fullscreen" centered>
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <div className="icon-wrapper">
            <MdDownload style={{ marginRight: '10px' }} onClick={() => handleDownloadFile(file)} id='downloadBtn' />
            <MdClose style={{ marginRight: '5px' }} onClick={handleCloseModal} id='closeBtn' />
          </div>
        </Modal.Header>

        <Modal.Body >
          <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} config={docViewerConfig} onError={(e) => setError(e)} />
        </Modal.Body>
      </Modal>
    );
  } catch (error) {
    console.error('Error rendering file viewer:', error);
    return (
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Erreur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Ce fichier ne peut pas être consulté. Veuillez réessayer ou télécharger.</p>
        </Modal.Body>
      </Modal>
    );
  }
};
export default FileViewerModal;
